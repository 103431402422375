import React from 'react'
import { PageTransition } from '../components/transition'
import { Helmet } from 'react-helmet'
import { styledPage } from '../layouts/base'

const DatenschutzPage = () => (
  <PageTransition css={styledPage}>
    <Helmet>
      <title>Datenschutz</title>
    </Helmet>
    <h1>Datenschutzerklärung</h1>
    <h2>Benennung der verantwortlichen Stelle</h2>
    <p>
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
      ist:
    </p>
    <p>
      Merle Ehm
      <br />
      Eichhofstraße 18
      <br />
      24116 Kiel
    </p>
    <p>
      Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
      über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
      (z.B. Namen, Kontaktdaten o. Ä.).
    </p>

    <h2>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
    <p>
      Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
      Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
      Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose
      Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
      Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>

    <h2>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h2>
    <p>
      Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
      Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
      der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
      unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
      Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{' '}
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        target="_blank"
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>

    <h2>Recht auf Datenübertragbarkeit</h2>
    <p>
      Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung
      oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
      an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem
      maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an
      einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
      technisch machbar ist.
    </p>

    <h2>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h2>
    <p>
      Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das
      Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck
      der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
      Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema
      personenbezogene Daten können Sie sich jederzeit über die im Impressum
      aufgeführten Kontaktmöglichkeiten an uns wenden.
    </p>

    <h2>SSL- bzw. TLS-Verschlüsselung</h2>
    <p>
      Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
      Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website
      eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
      Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine
      verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und
      am Schloss-Symbol in der Browserzeile.
    </p>

    <h2>Server-Log-Dateien</h2>
    <p>
      In Server-Log-Dateien erhebt und speichert der Provider der Website
      automatisch Informationen, die Ihr Browser automatisch an uns übermittelt.
      Dies sind:
    </p>
    <ul>
      <li>Besuchte Seite auf unserer Domain</li>
      <li>Datum und Uhrzeit der Serveranfrage</li>
      <li>Browsertyp und Browserversion</li>
      <li>Verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>IP-Adresse</li>
    </ul>
    <p>
      Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen
      statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO,
      der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
      vorvertraglicher Maßnahmen gestattet.
    </p>

    <h2>Speicherdauer von Beiträgen und Kommentaren</h2>
    <p>
      Beiträge und Kommentare sowie damit in Verbindung stehende Daten, wie
      beispielsweise IP-Adressen, werden gespeichert. Der Inhalt verbleibt auf
      unserer Website, bis er vollständig gelöscht wurde oder aus rechtlichen
      Gründen gelöscht werden musste.
    </p>
    <p>
      Die Speicherung der Beiträge und Kommentare erfolgt auf Grundlage Ihrer
      Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits
      erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
      formlose Mitteilung per E-Mail. Die Rechtmäßigkeit bereits erfolgter
      Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
    </p>

    <h2>Cookies</h2>
    <p>
      Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr
      Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser
      Angebot nutzerfreundlicher, effektiver und sicherer zu machen.{' '}
    </p>
    <p>
      Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende
      Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies
      auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies
      helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.
    </p>
    <p>
      Mit einem modernen Webbrowser können Sie das Setzen von Cookies
      überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so
      konfigurieren, dass Cookies mit dem Schließen des Programms von selbst
      gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte
      Funktionalität unserer Website zur Folge haben.
    </p>
    <p>
      Das Setzen von Cookies, die zur Ausübung elektronischer
      Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen
      erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf
      Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
      haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur
      technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste.
      Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt,
      werden diese in dieser Datenschutzerklärung separat behandelt.
    </p>
    <h2>Google Web Fonts</h2>
    <p>
      Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google
      Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
    </p>
    <p>
      Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns
      gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon
      welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den
      Abruf der Google Web Fonts von einem Server von Google in den USA und der
      damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich
      um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz
      von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
      Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der
      optimalen Darstellung und Übertragung unseres Webauftritts.
    </p>
    <p>
      Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen
      "Privacy Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die
      Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.
    </p>
    <p>
      Einzelheiten über Google Web Fonts finden Sie unter:{' '}
      <a href="https://www.google.com/fonts#AboutPlace:about">
        https://www.google.com/fonts#AboutPlace:about
      </a>{' '}
      und weitere Informationen in den Datenschutzbestimmungen von Google:{' '}
      <a href="https://policies.google.com/privacy/partners?hl=de">
        https://policies.google.com/privacy/partners?hl=de
      </a>
    </p>
    <p>
      Datenschutz-Konfigurator von{' '}
      <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">
        mein-datenschutzbeauftragter.de
      </a>
    </p>
  </PageTransition>
)

export default DatenschutzPage
